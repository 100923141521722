import { Injectable } from '@angular/core';
// import { AppModule } from '../app.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BasehttpService {

  private apiUrl: string = environment.apiUrl;
  currentUser: any;
  constructor(public http: HttpClient, private localSt: SessionStorageService) {
  }

  post(url, data, contentType): Promise<any> {
    this.currentUser = this.localSt.retrieve('user');
    const expandedHeaders = this.prepareHeader(null, contentType, this.currentUser);
    return this.http.post(this.apiUrl + url, data, { headers: expandedHeaders }).toPromise();
  }

  get(url, contentType): Promise<any> {
    this.currentUser = this.localSt.retrieve('user');
    const expandedHeaders = this.prepareHeader(null, contentType, this.currentUser);
    return this.http.get(this.apiUrl + url, { headers: expandedHeaders }).toPromise();
  }

  delete(url, contentType): Promise<any> {
    this.currentUser = this.localSt.retrieve('user');
    const expandedHeaders = this.prepareHeader(null, contentType, this.currentUser);
    return this.http.delete(this.apiUrl + url, { headers: expandedHeaders }).toPromise();
  }

  put(url, data, contentType): Promise<any> {
    this.currentUser = this.localSt.retrieve('user');
    const expandedHeaders = this.prepareHeader(null, contentType, this.currentUser);
    return this.http.put(this.apiUrl + url, data, { headers: expandedHeaders }).toPromise();
  }

  private prepareHeader(headers: HttpHeaders | null, contentType, user: any): HttpHeaders {
    headers = headers || new HttpHeaders();

    if (contentType === null) {
      headers = headers.set('Content-Type', 'application/json');
    } else {
      headers = headers.set('Content-Type', contentType);
    }

  
    if (user != null && user.access_token !== undefined) {
      headers = headers.set('X-ZUMO-AUTH', user.access_token);
    }
    return headers;
  }
}
