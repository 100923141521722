import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-choicelocal',
  templateUrl: './choicelocal.component.html',
  styleUrls: ['./choicelocal.component.css']
})
export class ChoicelocalComponent implements OnInit {

  branches: any = "0";
  phonenumber: any;
  cpf: any;
  order: any;
  errortel: boolean;
  errorcpf: boolean;
  errorbranches: boolean;
 

  constructor(private route: Router, private cart: CartService, private spinner: NgxSpinnerService) {

  }

  async ngOnInit() {
    //await this.cart.getBranches();
  }


  finish() {


    if (this.cpf == undefined || !this.validaCpf(this.cpf)) {
      this.errorcpf = true;
    } else {
      this.errorcpf = false;
    }
    if (this.phonenumber == undefined || this.phonenumber.length < 11) {
      this.errortel = true;
    } else {
      this.errortel = false;
    }
    if (this.branches == "0") {
      this.errorbranches = true;
    } else {
      this.errorbranches = false;
    }


    if (this.errorcpf || this.errortel || this.errorbranches) {
      Swal.fire({
        title: 'Error!',
        text: 'Campos obrigatórios!',
        type: 'error'
      });
      return;
    }


    var Attributes = '{';

    this.spinner.show();
    const items = this.cart.getItems();

    if (items.length == 0) {
      Swal.fire({
        title: 'Error!',
        text: 'Carrinho vazio!',
        type: 'error'
      });
      this.spinner.hide();
      return;
    }


    var total = 0;
    items.forEach(element => {

      Attributes += '"' + element.nome + '": { "title": "' + element.nome + '", "value": "' + element.valor + '" },';

      total = total + parseFloat(element.valor);
    });


     Attributes += '"Genero": { "title": "genre", "value": "' + this.cart.getGenre() + '" },';
     Attributes += '"Skin": { "title": "skin", "value": "' + this.cart.getSkin() + '" },';
     Attributes += '"Hair": { "title": "hair", "value": "' + this.cart.getHair() + '" }';
    Attributes += '}';
 
    var data = {
      "amount": total,
      "receivingOptionType": "CreditCard",
      "merchant": this.branches,
      "attributes": JSON.parse(Attributes),
      "campaignId": "1936fea0-af72-4399-826f-618326f47750",
      // "CallbackUrl": "https://pagolivreorderecommerce.azurewebsites.net/api/PostSendEmailEcommercePaid?code=1iTyklxIgQRH3sK4SfKWQLrL74jMnNhDGOflyPlNaXSvOJFRGia5qQ==",
      "orderGuests": [
        {
          "cPF": this.cpf,
          "mobileNumber": this.phonenumber
        }
      ]
    };

 

    this.cart.postOrder(data).subscribe((res: any) => {
      this.spinner.hide();
      this.cart.clearAll();
      window.location = res.paymentUrl;     
    });


  }

  public validaCpf(cpf: string): boolean {
    if (cpf === undefined) {
      return false;
    }
    if (cpf.length > 11) {
      cpf = cpf.replace('.', '').replace('.', '').replace('-', '');
    }
    if (cpf.length !== 11) {
      return false;
    }
    if ((cpf === '00000000000') || (cpf === '11111111111') || (cpf === '22222222222') || (cpf === '33333333333') || (cpf === '44444444444') || (cpf === '55555555555') || (cpf === '66666666666') || (cpf === '77777777777') || (cpf === '88888888888') || (cpf === '99999999999')) {
      return false;
    }
    let numero: number;
    numero = 0;
    let caracter: string;
    caracter = '';
    let numeros: string;
    numeros = '0123456789';
    let j: number;
    j = 10;
    let somatorio: number;
    somatorio = 0;
    let resto: number;
    resto = 0;
    let digito1: number;
    digito1 = 0;
    let digito2: number;
    digito2 = 0;
    let cpfAux: string;
    cpfAux = '';

    cpfAux = cpf.substring(0, 9);
    for (let i = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) === -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf !== cpfAux) {
      return false;
    } else {
      return true;
    }
  }

}
