import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js'

declare let gtag: Function;

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

    items = [];
    total: number = 0;

    constructor(
        private cart: CartService,
        private route: Router
    ) {
        this.items = this.cart.getItems();
        this.total = 0;
        this.items.forEach(element => {
            this.total = this.total + parseFloat(element.valor);
        });
    }

    removeProduct(item) {
        this.cart.removeCartItem(item);
        this.items = this.cart.getItems();
        this.total = 0;
        this.items.forEach(element => {
            this.total = this.total + parseFloat(element.valor);
        });
    }

    shop() {
        gtag('event', 'click', {
            'event_category': 'ecommerce',
            'event_label': 'continuar_comprando',
        });

        if (this.cart.getGenre() == 1) {
            this.route.navigate(['female'], { queryParamsHandling: 'merge' });
        } else {
            this.route.navigate(['male'], { queryParamsHandling: 'merge' });
        }

    }

    checkout() {
        gtag('event', 'click', {
            'event_category': 'ecommerce',
            'event_label': 'finalizar_compra',
        });


        this.items = this.cart.getItems();
        if (this.items.length == 0) {
            Swal.fire({
                title: 'Erro!',
                text: 'Carrinho vazio!',
                type: 'error'
            });

            return;
        }

        this.route.navigate(['local'], { queryParamsHandling: 'merge' });
    }

    ngOnInit() {
    }

}
