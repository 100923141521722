import { Component } from '@angular/core';
import {Location} from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CartService } from './service/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MicroServiceECommerce';
  home: boolean = false;
  showMenu = false;
  qtd: number;
  constructor(private _location: Location, private route: Router, private cart: CartService) {
  }
  ngOnInit() {
    setInterval(()=> {
      this.qtd = this.cart.qtd;
      
      if (this.route.url == "/home") {
        this.home = true;
      }
      if (this.route.url != "/home") {
        this.home = false;
      } 
    },100)
  }
  

  showBackClick() {
    this._location.back();
  }
  redirectTo(value) {
    if (value == 1) {
      this.route.navigate(['male'], { queryParamsHandling: 'merge' });
      this.showMenu = false;
    }
    if (value == 2) {
      this.route.navigate(['female'], { queryParamsHandling: 'merge' });
      this.showMenu = false;
    }
    if (value == 3) {
      this.route.navigate([''], { queryParamsHandling: 'merge' });
    }
  }
  buyCart() {
    this.route.navigate(['order'], { queryParamsHandling: 'merge' });
  }

  evaluation() {
    this.route.navigate(['evaluation'], { queryParamsHandling: 'merge' });
  }
}
