import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';

declare let gtag: Function;

@Component({
    selector: 'app-allproducts',
    templateUrl: './allproducts.component.html',
    styleUrls: ['./allproducts.component.css']
})
export class AllProducts implements OnInit {
    manItems: any;
    womanItems: any;
    constructor(private route: Router, private cart: CartService) {
        cart.getProductsPrices().subscribe(x => {
            this.manItems = (x as any).masculino;
            this.womanItems = (x as any).feminino;
        });
        // this.manItems = this.cart.data.masculino;
        // this.womanItems = this.cart.data.feminino;
    }

    ngOnInit() {
    }

    selectProduct(product: any) {
        if (product.genero === 'masculino') {
            this.cart.setGenre(2);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        }
        if (product.genero === 'feminino') {
            this.cart.setGenre(1);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        }
        this.cart.setProductCart(product);
        this.route.navigate(['product'], { queryParamsHandling: 'merge' });
    }

}
