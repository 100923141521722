import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TypeskinComponent } from './typeskin/typeskin.component';
import { TypehairComponent } from './typehair/typehair.component';
import { AllProducts } from './allproducts/allproducts.component';
import { ManchoiceComponent } from './manchoice/manchoice.component';
import { WomanchoiceComponent } from './womanchoice/womanchoice.component';
import { ProductComponent } from './product/product.component';
import { ChoicelocalComponent } from './choicelocal/choicelocal.component';
import { DoneevaluationComponent } from './doneevaluation/doneevaluation.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { OrderComponent } from './order/order.component';
import { ListpackageComponent } from './listpackage/listpackage.component';
import { ErrorComponent } from './error/error.component';


const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'typeskin', component: TypeskinComponent },
    { path: 'typehair', component: TypehairComponent },
    { path: 'allproducts', component: AllProducts },
    { path: 'male', component: ManchoiceComponent },
    { path: 'female', component: WomanchoiceComponent },
    { path: 'product', component: ProductComponent },
    { path: 'local', component: ChoicelocalComponent },
    { path: 'done', component: DoneevaluationComponent },
    { path: 'evaluation', component: EvaluationComponent },
    { path: 'institucional', component: InstitucionalComponent },
    { path: 'order', component: OrderComponent },
    { path: 'package', component: ListpackageComponent },
    { path: '**', component: ErrorComponent },
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
