import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';

@Component({
  selector: 'app-listpackage',
  templateUrl: './listpackage.component.html',
  styleUrls: ['./listpackage.component.css']
})
export class ListpackageComponent implements OnInit {

  promoItems: any;

  constructor(private route: Router, private cart: CartService) {
    cart.getProductsPrices().subscribe(x => {
      this.promoItems = (x as any).promotion;
    });
  }

  ngOnInit() {
  }

  promotion(promo) {
    this.cart.setPromo(promo);
    this.route.navigate(['choicegenre'], { queryParamsHandling: 'merge' });
  }

}
