import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js'



@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
    secondsLeft: number = JSON.parse(localStorage.getItem('seconds'));
    minutesLeft: number = JSON.parse(localStorage.getItem('minutes'));
    secondsInterval;
    carouselInterval;
    product: any;
    items: any = null;
    breturn:boolean = true;
    show = false;
    showMobile = false;
    promoItems: any;
    carousel = false;
    activeImg = "";
    skin: any;
    hair: any;
    constructor(
        private cart: CartService,
        private route: Router
    ) {
        this.product = this.cart.getProductCart();
        if (this.product == null) {
            this.route.navigate(['home'], { queryParamsHandling: 'merge' });
        }
        cart.getProductsPrices().subscribe(x => {
            if(this.cart.genre == 2){
                this.promoItems = (x as any).promotionMan;
            }else{
                this.promoItems = (x as any).promotionWoman;
            } 
        });
        // if(this.cart.genre == 2){
        //     this.promoItems = this.cart.data.promotionMan;
        // }
        // if(this.cart.genre == 1){
        //     this.promoItems = this.cart.data.promotionWoman;
        // }
        
        
    }
    startTimer() {
        
        this.carouselInterval = setInterval(() => {
            this.carousel = true;
            clearInterval(this.carouselInterval)
        },500)
        this.secondsInterval = setInterval(() => {
            
            if(this.secondsLeft >= 0) {
                localStorage.setItem('seconds', JSON.stringify(this.secondsLeft--));
            }
            if(this.minutesLeft < 0){
                clearInterval(this.secondsInterval);
                // this.route.navigate(['home'], { queryParamsHandling: 'merge' });
                localStorage.setItem('minutes', JSON.stringify(9));
                this.minutesLeft = JSON.parse(localStorage.getItem('minutes'));
                localStorage.setItem('seconds', JSON.stringify(59))
            //   clearInterval(this.secondsInterval);
            //   clearInterval(this.minutesInterval);
            }
            if(this.secondsLeft == -1){
                localStorage.setItem('seconds', JSON.stringify(59));
                this.secondsLeft = JSON.parse(localStorage.getItem('seconds'));
                localStorage.setItem('minutes', JSON.stringify(this.minutesLeft--));
            }
        },1000)
    }

    ngOnInit( ) {
        window.scrollTo(0,0)
        this.startTimer()
        this.product = this.cart.getProductCart();
        this.activeImg = this.product.img1
        this.skin = this.cart.getSkin()
        this.hair = this.cart.getHair()
    }
    
    changeImg(selectedImg:number){
        if(selectedImg === 1){
            this.activeImg = this.product.img1 
        }
        if(selectedImg === 2){
            this.activeImg = this.product.img2 
        }
        if(selectedImg === 3){
            this.activeImg = this.product.img3 
        }
        if(selectedImg === 4){
            this.activeImg = this.product.img4 
        }
        if(selectedImg === 5){
            this.activeImg = this.product.img5 
        }
        if(selectedImg === 6){
            this.activeImg = this.product.img6 
        }
        if(selectedImg === 7){
            this.activeImg = this.product.img7 
        }
        if(selectedImg === 8){
            this.activeImg = this.product.img8 
        }
    }

    addCart(product: { nome: string; }) {
        this.items = this.cart.getItems();
        this.breturn = true;
        // console.log(this.items);
        // console.log('chamou a função');
        if (this.items != null) {
            this.items.forEach((element: { nome: string; }) => {
                // console.log('entrou no for each / verificou se o produto está no carrinho');
                if (element.nome === product.nome) {
                    // console.log('lançou erro: existe no carrinho');
                    Swal.fire({
                        title: 'Error!',
                        text: 'Item já no carrinho!',
                        type: 'error'
                    });
                    this.breturn = false;
    
                }
                // console.log(this.breturn)
            });
        }
        if (this.breturn) {
            // console.log('adicionou produto no carrinho');
            this.cart.addToCart(this.product);
            this.route.navigate(['order'], { queryParamsHandling: 'merge' });
        }
    }

    selectProduct(product: any) {
        // console.log(product.genero)
        if(product.genero == "masculino"){
            this.cart.setGenre(2);
        } else {
            this.cart.setGenre(1);
        }
        this.cart.setProductCart(product);
        this.product = this.cart.getProductCart();
        this.activeImg = this.product.img1;
        window.scrollTo(0, 0);
    }

}


