import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-womanchoice',
    templateUrl: './womanchoice.component.html',
    styleUrls: ['./womanchoice.component.css']
})
export class WomanchoiceComponent implements OnInit {

    cartItems: any;
    items: any;
    itemsCartCheckout: boolean = false;

    showBack: boolean = false;
    showFront: boolean = false;
    showStartoption: boolean = false;
    showheadFrontup: boolean = false;


    constructor(
        private cart: CartService,
        private route: Router,
        private app: AppComponent) {
        cart.getProductsPrices().subscribe(x => {
            this.cartItems = (x as any).feminino;
        });
        // this.cartItems = this.cart.data.feminino;
    }

    ngOnInit() {
        this.showfrontClick();
        this.showStartClick();
        this.app.home = false;
        this.itemsCartCheckout = this.cart.getItems().length > 0;
    }

    checkout() {
        this.route.navigate(['order'], { queryParamsHandling: 'merge' });
    }


    hideAll() {

    }
    showbackClick() {
        this.showFront = false;
        this.showBack = true;
        this.showStartoption = true;
        this.showheadFrontup = false;
    }
    showfrontClick() {
        this.showFront = true;
        this.showBack = false;
        this.showStartoption = true;
        this.showheadFrontup = false;
    }
    showheadFrontupClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;

        this.items = [];
        this.items = this.cartItems.frente.cabeca;

    }

    showtroncoFrontupClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.frente.tronco;
    }

    showInferiorFrontupClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.frente.inferior;
    }



    showheadbackClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.verso.cabeca;

    }

    showtroncobackClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.verso.tronco;
    }

    showInferiorbackClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.verso.inferior;
    }

    showStartClick() {
        this.showStartoption = true;
    }
    showBackClick() {
        this.showStartoption = true;
        this.showheadFrontup = false;
    }

    selectProduct(product: any) {
        console.log(product.genero);
        if (product.genero === 'masculino') {
            this.cart.setGenre(2);
        } else {
            this.cart.setGenre(1);
        }
        this.cart.setProductCart(product);
        this.route.navigate(['product'], { queryParamsHandling: 'merge' });
    }

}
