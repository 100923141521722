import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localePT from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { TypeskinComponent } from './typeskin/typeskin.component';
import { TypehairComponent } from './typehair/typehair.component';
import { AllProducts } from './allproducts/allproducts.component';
import { OrderComponent } from './order/order.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { WomanchoiceComponent } from './womanchoice/womanchoice.component';
import { ManchoiceComponent } from './manchoice/manchoice.component';
import { ProductComponent } from './product/product.component';
import { ChoicelocalComponent } from './choicelocal/choicelocal.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { DoneevaluationComponent } from './doneevaluation/doneevaluation.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { BasehttpService } from './service/basehttp.service';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { ListpackageComponent } from './listpackage/listpackage.component';
import { NgxMaskModule } from 'ngx-mask';
import {IvyCarouselModule} from 'angular-responsive-carousel';



registerLocaleData(localePT);



@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ErrorComponent,
        TypeskinComponent,
        TypehairComponent,
        AllProducts,
        OrderComponent,
        WomanchoiceComponent,
        ManchoiceComponent,
        ProductComponent,
        ChoicelocalComponent,
        EvaluationComponent,
        DoneevaluationComponent,
        InstitucionalComponent,
        ListpackageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatMenuModule,
        NgxWebstorageModule.forRoot(),
        HttpClientModule,
        NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        IvyCarouselModule
    ],
    providers: [BasehttpService, { provide: LOCALE_ID, useValue: 'pt' }],
    bootstrap: [AppComponent]
})
export class AppModule {
//     static getUrl() {
//         return environment.apiUrl;
//     }
}
