import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  items: Array<any> = [];
  productDetail: any;
  itemPromo: any = null;
  qtd: number = 0;
  //  1 Feminino e 2 Masculino
  genre: number;

  skin: number;
  hair: String;
  

  constructor(private http: HttpClient) { 
  }

  addToCart(product) {
    this.items = this.items || [];
    this.items.push(product);
    localStorage.setItem('cart', JSON.stringify(this.items));
    this.qtd =  this.getItems().length;
  }
  // addToCart(product) {
  //   this.items.push(product);
  // }

  getItems() {
    this.items = JSON.parse(localStorage.getItem('cart'))
    return this.items;
  }

  getQtd() {
    this.qtd = this.getItems().length
    if(this.qtd = null){
      this.qtd = 0;
    }
    return this.qtd;
  }

  // getItems() {
  //   return this.items;
  // }


  removeCartItem(item) {
    var index = this.items.indexOf(item);
    if (index > -1) {
      this.items.splice(index, 1);
    }
    localStorage.setItem('cart', JSON.stringify(this.items))
    this.qtd =  this.getItems().length;
  }

  // removeCartItem(item) {
  //   var index = this.items.indexOf(item);
  //   if (index > -1) {
  //     this.items.splice(index, 1);
  //   }
  // }

  clearCart() {
    this.items = [];
    return this.items;
  }

  getProductsPrices() {
    return this.http.get('/assets/json/products.json');
    // return JSON.parse('src/assets/json/products.json')
  }
  
  setProductCart(product) {
    this.productDetail = product;
    localStorage.setItem('product', JSON.stringify(this.productDetail));
  }

  getProductCart() {
    // return this.productDetail;
    return JSON.parse(localStorage.getItem('product'));
  }


  setGenre(_genre: number) {
    this.genre = _genre;
  }

  getGenre() {
    return this.genre;
  }

  clearAll() {
    this.items = [];
    this.productDetail = null;
    this.genre = null;
    this.itemPromo = null;
    this.skin = null;
    this.hair = null;
    localStorage.clear();
  }

  setPromo(promo) {
    this.itemPromo = promo;
  }

  getPromo() {
    return this.itemPromo;
  }


  setSkin(value) {
    this.skin = value;
    localStorage.setItem('skin', JSON.stringify(this.skin));
  }

  getSkin() {
    // return this.skin;
    return JSON.parse(localStorage.getItem('skin'));
  }

  setHair(value) {
    this.hair = value;
    localStorage.setItem('hair', JSON.stringify(this.hair));
  }

  getHair() {
    // return this.hair;
    return JSON.parse(localStorage.getItem('hair'));
  }


  getBranches(): Promise<any> {

    var headers = new HttpHeaders();
    headers = headers.set('Ocp-Apim-Trace', 'true');
    headers = headers.set('Ocp-Apim-Subscription-Key', '5d2e937ce927452dac4ff06166b86585');
    headers = headers.set('Content-Type', 'text/plain');

    return this.http.get("http://apigateway.pagolivre.co/openlaser/branches", { headers: headers }).toPromise();
  }

  postOrder(data: any) {

    var headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');  
  
    //return this.http.post("http://localhost:7071/api/createOrderOpenLaser", data, { headers: headers });
    return this.http.post("https://openlaserecommerce.azurewebsites.net/api/createOrderOpenLaser?code=cCB9agFFTawkpAkGDJEoPd2Ga3i/1o5oTt40EGho0XnWwPsNfSMkoA==", data, { headers: headers });
  }

  postEmailEvaluation(data: any) {

    var headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');    
    //return this.http.post("http://localhost:7071/api/PostSendEmailEcommerceEvaluation", data, { headers: headers });
    return this.http.post("https://pagolivreorderecommerce.azurewebsites.net/api/PostSendEmailEcommerceEvaluation?code=ZxgXm7aVFosQpLU6CFjSH3nVcOCAZY1aEeYkEosP27ScbnzHciJdOg==", data, { headers: headers });
  }


}
