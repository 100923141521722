import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
    selector: 'app-evaluation',
    templateUrl: './evaluation.component.html',
    styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {

    branches: any = "0";
    errorbranches: boolean = false;
    errorName: boolean = false;
    errorEmail: boolean = false;
    errorPhone: boolean = false;
    name: any;
    email: any;
    phonenumber: any;


    constructor(private route: Router, private cart: CartService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
    }


    sendEmail() {

        if (this.phonenumber == undefined || this.phonenumber.length < 11) {
            this.errorPhone = true;
        } else {
            this.errorPhone = false;
        }

        if (this.name == undefined) {
            this.errorName = true;
        } else {
            this.errorName = false;
        }

        if (this.email == undefined) {
            this.errorEmail = true;
        } else {
            this.errorEmail = false;
        }

        if (this.branches == 0) {
            this.errorbranches = true;
        } else {
            this.errorbranches = false;
        }

        if (this.errorPhone || this.errorName || this.errorEmail || this.errorbranches) {
            Swal.fire({
                title: 'Error!',
                text: 'Campos obrigatórios!',
                type: 'error'
            });
            return;
        }


        this.spinner.show();

        // var pele = this.cart.getSkin();
        // var pelo = this.cart.getHair();

        var parameters = {
            "phonenumber": this.phonenumber,
            // "pele": pele,
            // "pelo": pelo,
            "name": this.name,
            "email": this.email,
            "localstore": this.branches
        };


        var data = {
            "templateId": "d-46309ec9b48a456498b3b18feeaeb879",
            "to": {
                "email":"atendimento@openlaser.com.br",
                "name": "Atendimento"
            },
            "data": parameters
        };

        this.cart.postEmailEvaluation(data).subscribe((res: any) => {
            this.spinner.hide();
            Swal.fire({
                title: 'Solicitação enviada',
                text: 'Seu pré-agendamento foi realizado com sucesso. Aguarde contato da unidade selecionada para a confirmação de sua sessão!',
                type: 'success'
            });
        });


    }

}
