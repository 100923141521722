import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';

declare let gtag: Function;

@Component({
  selector: 'app-typehair',
  templateUrl: './typehair.component.html',
  styleUrls: ['./typehair.component.css']
})
export class TypehairComponent implements OnInit {

  constructor(private route: Router, private cart: CartService) { }

  ngOnInit() {
  }

  selectHair(color: any) {
    gtag('event', 'click', {
      'event_category': 'pelo',
      'event_label': color,
      });
    
  this.cart.setHair(color);
    switch (color) {
      case 'Branco':
      case 'Ruivo':
          this.route.navigate(['evaluation'], { queryParamsHandling: 'merge' });
          break;
      default:
          if (this.cart.getPromo() != null) {
            this.cart.setProductCart(this.cart.getPromo());
            this.route.navigate(['product'], { queryParamsHandling: 'merge' });
          } else {
  
            if (this.cart.getGenre() == 1) {
              this.route.navigate(['female'], { queryParamsHandling: 'merge' });
            } else {
              this.route.navigate(['male'], { queryParamsHandling: 'merge' });
            }
          }
          break;
    }
  }

}
