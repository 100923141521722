import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-manchoice',
    templateUrl: './manchoice.component.html',
    styleUrls: ['./manchoice.component.css']
})
export class ManchoiceComponent implements OnInit {

    cartItems: any;
    items: any;
    showBack: boolean = false;
    showFront: boolean = false;
    showStartoption: boolean = false;
    showheadFrontup: boolean = false;
    itemsCartCheckout: boolean = false;

    constructor(
        private cart: CartService,
        private route: Router,
        private app: AppComponent) {
        cart.getProductsPrices().subscribe(x => {
            this.cartItems = (x as any).masculino;
        });
        // this.cartItems = this.cart.data.masculino;
    }

    ngOnInit() {
        this.showfrontClick();
        this.showStartClick();
        this.app.home = false;
        this.itemsCartCheckout = this.cart.getItems().length > 0;
    }

    checkout() {
        this.route.navigate(['order'], { queryParamsHandling: 'merge' });
    }

    showbackClick() {
        this.showFront = false;
        this.showBack = true;
        this.showStartoption = true;
        this.showheadFrontup = false;
    }
    showfrontClick() {
        this.showFront = true;
        this.showBack = false;
        this.showStartoption = true;
        this.showheadFrontup = false;
    }
    showStartClick() {
        this.showStartoption = true;
    }
    showBackClick() {
        this.showStartoption = true;
        this.showheadFrontup = false;
    }

    showheadFrontupClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;

        this.items = [];
        this.items = this.cartItems.frente.cabeca;

    }

    showtroncoFrontupClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.frente.tronco;
    }

    showInferiorFrontupClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.frente.inferior;
    }


    showheadbackClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.verso.cabeca;

    }

    showtroncobackClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.verso.tronco;
    }

    showInferiorbackClick() {
        this.showheadFrontup = true;
        this.showStartoption = false;
        this.items = [];
        this.items = this.cartItems.verso.inferior;
    }

    selectProduct(product: any) {
        console.log(product.genero)
        if(product.genero == "masculino"){
            this.cart.setGenre(2);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        }
        if(product.genero == "feminino"){
            this.cart.setGenre(1);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        }
        this.cart.setProductCart(product);
        this.route.navigate(['product'], { queryParamsHandling: 'merge' });
    }

}
