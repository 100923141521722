import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { AppComponent } from '../app.component';
declare let gtag: Function;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    promoItems: any;
    moreBuyHome: any;
    carousel:boolean;
    carrouselInterval;

    constructor(private route: Router, private cart: CartService) {
        cart.getProductsPrices().subscribe(x => {
            this.promoItems = (x as any).promotionHome;
            this.moreBuyHome = (x as any).moreBuyHome;
        });
        // this.promoItems = this.cart.data.promotionHome;
        // this.moreBuyHome = this.cart.data.moreBuyHome;
    }

    ngOnInit() {
        window.scrollTo(0,0)
        // this.cart.clearAll();
        this.carrouselInterval = setInterval(() => {
            this.carousel = true;
            clearInterval(this.carrouselInterval);
        },100)
    }
    buy() {
        gtag('event', 'click', {
            'event_category': 'individuais',
            'event_label': 'ver_mais',
        });

        this.route.navigate(['allproducts'], { queryParamsHandling: 'merge' });
    }

    promotionSigle(promo, genero) {

        gtag('event', 'click', {
            'event_category': 'individuais',
            'event_label': promo.nome,
        });

        this.cart.setPromo(promo);

        if (genero == "M") {
            this.cart.setGenre(1);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        } else {
            this.cart.setGenre(2);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        }
        this.cart.setProductCart(this.cart.getPromo());
        this.route.navigate(['product'], { queryParamsHandling: 'merge' });
    }

    promotionPackage(promo, genero) {

        let nome = promo.nome.replace(' + ', '_').replace(' + ', '_');

        gtag('event', 'click', {
            'event_category': 'pacotes',
            'event_label': nome,
        });
        this.cart.setPromo(promo);

        if (genero == "masculino") {
            this.cart.setGenre(2);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        } else {
            this.cart.setGenre(1);
            this.cart.setSkin(2);
            this.cart.setHair(2);
        }
        this.cart.setProductCart(this.cart.getPromo());
        this.route.navigate(['product'], { queryParamsHandling: 'merge' });
    }

    allpromo() {
        gtag('event', 'click', {
            'event_category': 'pacotes',
            'event_label': 'ver_mais',
        });
        this.route.navigate(['package'], { queryParamsHandling: 'merge' });
    }


    buyBannerMan() {
        gtag('event', 'click', {
            'event_category': 'individuais',
            'event_label': 'costas',
        });


        this.cart.getProductsPrices().subscribe(x => {
            var promo = (x as any).promoBannerMan;
            this.cart.setPromo(promo);
            this.cart.setGenre(2);
            this.route.navigate(['typeskin'], { queryParamsHandling: 'merge' });
        });
    }

    buyBannerWoman() {
        gtag('event', 'click', {
            'event_category': 'individuais',
            'event_label': 'virilha',
        });
        this.cart.getProductsPrices().subscribe(x => {
            var promo = (x as any).promoBannerWoMan;
            this.cart.setPromo(promo);
            this.cart.setGenre(1);
            this.route.navigate(['typeskin'], { queryParamsHandling: 'merge' });
        });
    }
}
