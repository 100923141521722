import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';

declare let gtag: Function;

@Component({
    selector: 'app-typeskin',
    templateUrl: './typeskin.component.html',
    styleUrls: ['./typeskin.component.css']
})
export class TypeskinComponent implements OnInit {

    genre: number = 0;

    constructor(private route: Router, private cart: CartService) { }

    ngOnInit() {
        this.genre = this.cart.getGenre();
    }

    selectSkin(color: any) {

        gtag('event', 'click', {
            'event_category': 'pele',
            'event_label': color,
            });
          
        this.cart.setSkin(color);
        switch (color) {
            case 1:              
            case 2:               
            case 3:              
            case 4:              
            case 5:              
            case 6:
                if (this.cart.getPromo() != null) {
                    this.cart.setProductCart(this.cart.getPromo());
                    this.route.navigate(['product'], { queryParamsHandling: 'merge' });
                  } else {
          
                    if (this.cart.getGenre() == 1) {
                      this.route.navigate(['female'], { queryParamsHandling: 'merge' });
                    } else {
                      this.route.navigate(['male'], { queryParamsHandling: 'merge' });
                    }
                  }
                break;
            case 7:               
            case 8:               
            case 9:               
            case 10:
                this.route.navigate(['evaluation'], { queryParamsHandling: 'merge' });
                break;

        }
    }
}
